import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
// import Signin from "../pages/Signin";
import Login from "../pages/Login";
import Login1 from "../pages/Login1";

import PrivacyPolicy from "./../pages/PrivacyPolicy";
import ContactUS from "./../pages/ContactUS";
import TermsOfService from "./../pages/TermsOfService";
import SignUp from "./../pages/SignUp/SignUp";
import Dashboard from "../pages/Dashboard/Dashboard";
import JobcardSummary from "../pages/JobcardSummary/JobcardSummary";
import MyProfile from "../pages/MyProfile";
import { useLocation } from "react-router-dom";
import CreateJobcard from "../pages/CreateJobcard/CreateJobcard";
import PrintJobCard from "../pages/JobCardDetails/PrintJobCard";
import DefaultIssuesOptionList from "./../pages/ServiceManagement/DefaultIssuesOptionList";
import ItemsReceivedList from "./../pages/ServiceManagement/ItemsReceivedList";
import AdditionalRequirementList from "./../pages/ServiceManagement/AdditionalRequirementList";
import BrandsList from "./../pages/ServiceManagement/BrandsList";
import ProductList from "./../pages/ServiceManagement/ProductList";
import JobCardDetails from "./../pages/JobCardDetails/JobCardDetails";
import UsersList from "./../pages/UsersList/UsersList";
import ActiontakenList from "./../pages/ServiceManagement/ActiontakenList";
import EngineerObservationList from "./../pages/ServiceManagement/EngineerObservationList";
import CustomerNoteList from "./../pages/ServiceManagement/CustomerNoteList";
import StoresList from "./../pages/StoresList/StoresList";
import StoresReport from "./../pages/StoresReport/StoresReport";
import MyAccount from "./../pages/userManagement/index";
import CustomersList from "./../pages/CustomersList/CustomersList";
import ChangePassword from "./../pages/ChangePassword/ChangePassword";
import JobCardsApproval from "./../pages/JobCardsApproval/JobCardsApproval";
import PrintPageSetting from "./../pages/PrintPageSetting/PrintPageSetting";
import CreateBookingService from "./../pages/BookingService/CreateBookingService/CreateBookingService";
import BookingServiceSummary from "./../pages/BookingService/BookingServiceSummary/BookingServiceSummary";

import CreateLeadManagement from "./../pages/LeadManagement/CreateLeadManagement/CreateLeadManagement";
import LeadSummary from "./../pages/LeadManagement/LeadSummary/LeadSummary";
import PaymentModeList from "./../pages/LeadManagement/PaymentModeList";
import ConfigurationList from "./../pages/LeadManagement/ConfigurationList";
import LeadActiontakenList from "./../pages/LeadManagement/LeadActiontakenList";
import LeadStatusList from "./../pages/LeadManagement/LeadStatusList";
import ProductorWise from "./../pages/Reports/ProductorWise";
import BrandWise from "./../pages/Reports/BrandWise";
import EngineerWise from "./../pages/Reports/EngineerWise";
import ActionTakenWise from "./../pages/Reports/ActionTakenWise";
import StatusWise from "./../pages/Reports/StatusWise";
import CommonReports from "./../pages/Reports/CommonReports";
import TodayStatusReport from "./../pages/Reports/TodayStatusReport";
import AboutUs from "./../pages/AboutUs/AboutUs";
import CheckJobcardStatus from "./../pages/CheckJobcardStatus/CheckJobcardStatus";
import EmailsReport from "./../pages/EmailsReport/EmailsReport";
import Services from "./../pages/Services";
import LeadItemDetails from "./../pages/LeadItemDetails/LeadItemDetails";
import Testing from "../pages/Testing/Testing";
const MainRoutes: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Switch>
      {/*Route exact path="/" component={Home} />*/}

      <Route exact path="/" component={Services} />

      <Route exact path="/contactUS" component={ContactUS} />
      <Route exact path="/Services" component={Services} />
      {/* <Route exact path="/storesReport" component={StoresReport} /> */}
      <Route exact path="/home" component={Services} />
      {/* <Route exact path="/signin" component={Signin} /> */}
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/Login1" component={Login1} />
      <Route exact path="/Testingapp" component={Testing} />
      <Route exact path="/TermsOfService" component={TermsOfService} />
      <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />

      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/jobcardsummary" component={JobcardSummary} />
      <Route exact path="/jobcarddetails/:jobId" component={JobCardDetails} />

      <Route exact path="/jobCardsApproval" component={JobCardsApproval} />
      <Route exact path="/changepassword" component={ChangePassword} />
      <Route exact path="/myProfile" component={MyProfile} />
      <Route exact path="/createjobcard" component={CreateJobcard} />
      <Route exact path="/editjobcard/:jobId" component={CreateJobcard} />
      <Route
        exact
        path="/DefaultIssuesOptionList"
        component={DefaultIssuesOptionList}
      />
      <Route exact path="/ItemsReceivedList" component={ItemsReceivedList} />
      <Route
        exact
        path="/AdditionalRequirementList"
        component={AdditionalRequirementList}
      />
      <Route exact path="/CustomerNoteList" component={CustomerNoteList} />
      <Route exact path="/BrandList" component={BrandsList} />
      <Route exact path="/usersList" component={UsersList} />
      <Route exact path="/customersList" component={CustomersList} />
      <Route exact path="/ProductList" component={ProductList} />
      <Route exact path="/actiontakenList" component={ActiontakenList} />
      <Route exact path="/storesList" component={StoresList} />
      <Route exact path="/storesReport" component={StoresReport} />

      <Route exact path="/storesList1" component={MyAccount} />
      <Route exact path="/printpagesetting" component={PrintPageSetting} />
      <Route
        exact
        path="/createBookingService"
        component={CreateBookingService}
      />
      <Route exact path="/checkJobcardStatus" component={CheckJobcardStatus} />
      <Route
        exact
        path="/editBookingService/:bookingId"
        component={CreateBookingService}
      />
      <Route
        exact
        path="/bookingdetails/:bookingId"
        component={CreateBookingService}
      />

      <Route
        exact
        path="/BookingServiceSummary"
        component={BookingServiceSummary}
      />

      <Route exact path="/CreateLead" component={CreateLeadManagement} />
      <Route exact path="/editLead/:leadId" component={CreateLeadManagement} />

      <Route
        exact
        path="/EngineerObservationList"
        component={EngineerObservationList}
      />
      <Route exact path="/ProductList" component={ProductList} />
      <Route exact path="/printJobCard/:jobId" component={PrintJobCard} />
      <Route exact path="/LeadSummary" component={LeadSummary} />
      <Route exact path="/PaymentModeList" component={PaymentModeList} />
      <Route exact path="/configurationList" component={ConfigurationList} />
      <Route exact path="/LeadStatusList" component={LeadStatusList} />
      <Route exact path="/ProductorWise" component={ProductorWise} />
      <Route exact path="/BrandWise" component={BrandWise} />
      <Route exact path="/StatusWise" component={StatusWise} />
      <Route exact path="/todayStatusReport" component={TodayStatusReport} />
      <Route exact path="/EngineerWise" component={EngineerWise} />
      <Route exact path="/Reports" component={CommonReports} />
      <Route exact path="/ActionTakenWise" component={ActionTakenWise} />
      <Route exact path="/leaddetails/:leadId" component={LeadItemDetails} />
      <Route exact path="/AboutUs" component={AboutUs} />
      <Route exact path="/emailLogs" component={EmailsReport} />

      <Route
        exact
        path="/LeadActiontakenList"
        component={LeadActiontakenList}
      />
    </Switch>
  );
};

export default MainRoutes;
