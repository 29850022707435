import { JobCardDashboardStyled } from "./JobCardDashboard.styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Marquee from "react-fast-marquee";

import { getDashboards } from "./../../redux/slices/serviceManagement/serviceManagementService";

const JobCardDashboard = (props: any) => {
  const { statusUpdate, currectActiveTab } = props;
  const { dashboardData } = useSelector(
    (state: any) => state.serviceManagement
  );
  const { error, loading, user, loginStoreDetails } = useSelector(
    (state: any) => state.auth
  );
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("user", user);
    if (user?.id) {
      dispatch(getDashboards(false));
    } else {
      history.push("/login");
    }
  }, []);
  useEffect(() => {
    if (currectActiveTab === "Your Dashboard") {
      dispatch(getDashboards(true));
    } else {
      dispatch(getDashboards(false));
    }
  }, [currectActiveTab]);
  console.log("dashboardData", dashboardData);

  return (
    <>
      <JobCardDashboardStyled>
        {/* <div className="job-card-dashboard-alerts">
          <h3>Alerts</h3>
          <div className="alerts-text-row">
            <Marquee pauseOnHover={true}>
              <p>
                <span className="dash_item-sec"></span>
                {` `}
                <span className="dash_item_text">
                  I can be a React component, multiple React components, or just
                  some text.
                </span>
              </p>
              <p>
                <span className="dash_item-sec"></span> {` `}
                <span className="dash_item_text">
                  We are coming with New features on Monthly Basis.
                </span>
              </p>
              <p>
                <span className="dash_item-sec"></span> {` `}
                <span className="dash_item_text">
                  Coming Soon Launching New Module!
                </span>
              </p>
            </Marquee>
          </div>
        </div> */}
        <div className="job-card-dashboard">
          <div>
            <div className="box-all-rows">
              <div>
                <div className="box-column">
                  <div
                    className="box-row box-row1"
                    onClick={() => statusUpdate("Open")}
                  >
                    <p>Create</p>
                    <p>{dashboardData?.openCalls}</p>
                  </div>
                  <div
                    className="box-row box-row2"
                    onClick={() => statusUpdate("Warranty Crated")}
                  >
                    <p>Warranty Created</p>
                    <p>{dashboardData?.warrantyCrated}</p>
                  </div>
                </div>
                <div className="box-column">
                  <div
                    className="box-row box-row3"
                    onClick={() => statusUpdate("Repair Start")}
                  >
                    <p>Repair Start</p>
                    <p>{dashboardData?.repairStart}</p>
                  </div>
                  <div
                    className="box-row box-row4 long_text"
                    onClick={() => statusUpdate("Waiting for Pickup (OW)")}
                  >
                    <p>Waiting for Pickup</p>
                    <p>{dashboardData?.waitingforPickup}</p>
                  </div>
                </div>
                <div className="box-column">
                  <div
                    className="box-row box-row5"
                    onClick={() => statusUpdate("Repair Closed")}
                  >
                    <p>Repair Closed</p>
                    <p>{dashboardData?.repairClosed}</p>
                  </div>
                  <div
                    className="box-row box-row6"
                    onClick={() => statusUpdate("Open Warranty")}
                  >
                    <p>Out for Warranty</p>
                    <p>{dashboardData?.openWarrantyCalls}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box-column">
                  <div
                    className="box-row box-row7"
                    onClick={() => statusUpdate("Internal Billing")}
                  >
                    <p>Internal Billing</p>
                    <p>{dashboardData?.internalBilling}</p>
                  </div>
                  <div
                    className="box-row box-row8"
                    onClick={() => statusUpdate("Quotion")}
                  >
                    <p>Quotation</p>
                    <p>{dashboardData?.quotionCount}</p>
                  </div>
                </div>
                <div className="box-column">
                  <div
                    className="box-row box-row9"
                    onClick={() => statusUpdate("Part Required")}
                  >
                    <p>Parts Required</p>
                    <p>{dashboardData?.partRequiredCalls}</p>
                  </div>
                  <div
                    className="box-row box-row10"
                    onClick={() => statusUpdate("Quotion Approved")}
                  >
                    <p>Quotation Approved</p>
                    <p>{dashboardData?.quotionApprovedCount}</p>
                  </div>
                </div>
                <div className="box-column">
                  <div
                    className="box-row box-row11"
                    onClick={() => statusUpdate("Need Tech Support")}
                  >
                    <p>Need Tech Sup</p>
                    <p>{dashboardData?.needTechSupportCalls}</p>
                  </div>
                  <div
                    className="box-row box-row12"
                    onClick={() => statusUpdate("Pending")}
                  >
                    <p>Pending</p>
                    <p>{dashboardData?.pendingCalls}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box-column">
                  <div
                    className="box-row box-row13"
                    onClick={() => statusUpdate("Low", "Priority")}
                  >
                    <p>Low</p>
                    <p>{dashboardData?.lowPriorityCalls}</p>
                  </div>
                  <div
                    className="box-row box-row14"
                    onClick={() => statusUpdate("Online Repair")}
                  >
                    <p>Book to Repair</p>
                    <p>{dashboardData?.onlineRepairCount}</p>
                  </div>
                </div>
                <div className="box-column">
                  <div
                    className="box-row box-row15"
                    onClick={() => statusUpdate("Medium", "Priority")}
                  >
                    <p>Medium</p>
                    <p>{dashboardData?.mediumPriorityCalls}</p>
                  </div>
                  <div
                    className="box-row box-row16 long_text"
                    onClick={() => statusUpdate("Online Repair Closed")}
                  >
                    <p>Book to Repair Closed</p>
                    <p>{dashboardData?.onlineRepairClosedCount}</p>
                  </div>
                </div>
                <div className="box-column">
                  <div
                    className="box-row box-row17"
                    onClick={() => statusUpdate("High", "Priority")}
                  >
                    <p>High</p>
                    <p>{dashboardData?.highPriorityCalls}</p>
                  </div>
                  <div
                    className="box-row box-row18"
                    onClick={() => statusUpdate("Return Without Repair")}
                  >
                    <p>Return With Out Repair</p>
                    <p>{dashboardData?.returnWithoutRepair}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="box-column">
                  <div
                    className="box-row box-row19 long_text"
                    onClick={() => statusUpdate("Ready for Delivery")}
                  >
                    <p>Ready for Delivery</p>
                    <p>{dashboardData?.readyForDeliveryCalls}</p>
                  </div>
                  <div
                    className="box-row box-row20 long_text"
                    onClick={() => statusUpdate("Intentionally Left Out")}
                  >
                    <p>Intentionally Left Out</p>
                    <p>{dashboardData?.intentionallyLeftOutCalls}</p>
                  </div>
                </div>
                <div className="box-column">
                  <div
                    className="box-row box-row21"
                    // onClick={() => statusUpdate()}
                  >
                    <p>Total</p>
                    <p>{dashboardData?.noOfCalls}</p>
                  </div>
                  <div
                    className="box-row box-row22"
                    onClick={() => statusUpdate("Buy Back")}
                  >
                    <p>Buy Back</p>
                    <p>{dashboardData?.buyBackCalls}</p>
                  </div>
                </div>
                <div className="box-column">
                  <div
                    className="box-row box-row23"
                    onClick={() => statusUpdate("Closed")}
                  >
                    <p>Closed</p>
                    <p>{dashboardData?.closedCalls}</p>
                  </div>
                  <div
                    className="box-row box-row24"
                    onClick={() => statusUpdate("Cancel")}
                  >
                    <p>Cancel</p>
                    <p>{dashboardData?.cancelCalls}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </JobCardDashboardStyled>
    </>
  );
};
export default JobCardDashboard;
