import { useSelector } from "react-redux";
// import { ReactComponent as OrdersIconGray } from "../../Assets/OrdersIconGray.svg";
// import { ReactComponent as SignInIconGray } from "../../Assets/SignInIconGray.svg";

import { RootState } from "../../redux/store";
import { isRaskoAccount } from "./../../Scenes/common";
import { useLocation } from "react-router-dom";

const Cart = (props) => {
  const { gotoNextPage, handleLogout, showLoginBtn } = props;
  const { error, loading, user } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const currentUrl = `${location.pathname}${
    location.search ? location.search : ""
  }`;
  console.log("user", user);

  return (
    <div className="menuDropdownMainSection">
      <div
        className="items-list"
        onClick={() => {
          gotoNextPage("/myProfile");
        }}
      >
        <div className="content-icon"> </div>
        <div className="content-text"> My Profile </div>
      </div>
      <div
        className="items-list"
        onClick={() => {
          gotoNextPage("/myProfile");
        }}
      >
        <div className="content-icon"> </div>
        <div className="content-text"> Settings</div>
      </div>
      <div
        className="items-list"
        onClick={() => {
          gotoNextPage("/changepassword");
        }}
      >
        <div className="content-icon"> </div>
        <div className="content-text"> Change Password</div>
      </div>

      {isRaskoAccount(user?.storeId) && (
        <>
          <div
            className="items-list"
            onClick={() => {
              gotoNextPage("/storesList");
            }}
          >
            <div className="content-icon"> </div>
            <div className="content-text"> Store List</div>
          </div>

          <div
            className="items-list"
            onClick={() => {
              gotoNextPage("/storesReport");
            }}
          >
            <div className="content-icon"> </div>
            <div className="content-text"> Stores Report</div>
          </div>
          <div
            className="items-list"
            onClick={() => {
              gotoNextPage("/emailLogs");
            }}
          >
            <div className="content-icon"> </div>
            <div className="content-text"> Email Logs</div>
          </div>
        </>
      )}
      <div
        className="items-list items-list-signout"
        onClick={() => {
          handleLogout();
        }}
      >
        <div className="content-icon"> </div>
        <div className="content-text"> Sign Out</div>
      </div>
      <style jsx>
        {`
          .red {
            color: red;
          }
        `}
      </style>
    </div>
  );
};

export default Cart;
