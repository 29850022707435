import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAllBrands,
  getDashboards,
  getDisplayJobCardFormApi,
  getBrandsByProductIdApi,
  getItemsRecievedByProductIdAPi,
  getDefaultIssuesByProductIdAPi,
  getCustomerAdditionalReqAPi,
  getJobcardSummaryApi,
  getFilteredjobcardsummaryApi,
  getJobCardDetailsApi,
  getJobCardDetailsWithMobileNoApi,
  getProductsListApi,
  getProductDetails,
  getBrandDetails,
  getItemReceivedList,
  getDefaultIssuesList,
  getAdditionalRequirementsList,
  getActionTakenList,
  getEngineerObservationList,
  getCustomerNotesList,
  getActionDetailsDetails,
  getEngineerobservationDetails,
  getEdititemReceivedDetails,
  getDefaultIssuesOptionDetails,
  getAdditionalRequirementsOptionDetails,
  getJobCardOptionsBasedOnProductId,
} from "./serviceManagementService";

export interface serviceManagementState {
  loading: boolean;
  error: any;
  brandsList: any;
  dashboardData: any;
  displayJobCardDetails: any;
  brandsByProductIdResp: any;
  itemsRecievedByProductIdResp: any;
  defaultIssuesByProductIdResp: any;
  customerAdditionalReqResp: any;
  jobCardSummaryDetails: any;
  approvalJobCards: any;
  jobCardDetails: any;
  productsList: any;
  productDetails: any;
  brandDetails: any;
  itemsReceivedList: any;
  defaultIssuesList: any;
  additionalRequirementsList: any;
  actionTakenList: any;
  engineerObservationList: any;
  customerNotesList: any;
  actionTakenDetails: any;
  engineerObservationDetails: any;
  edititemReceivedDetails: any;
  defaultIssuesOptionDetails: any;
  additionalRequirementsOptionDetails: any;
  getJobCardOptionsBasedOnProductIdResp: any;
  guestJobCardDetails: any;
}
const userData: string | null = localStorage.getItem("user");
const initialState: serviceManagementState = {
  loading: false,
  error: null,
  brandsList: [],
  dashboardData: {},
  displayJobCardDetails: {},
  brandsByProductIdResp: [],
  itemsRecievedByProductIdResp: [],
  defaultIssuesByProductIdResp: [],
  customerAdditionalReqResp: [],
  jobCardSummaryDetails: {},
  approvalJobCards: {},
  jobCardDetails: {},
  productsList: [],
  productDetails: {},
  brandDetails: {},
  itemsReceivedList: [],
  defaultIssuesList: [],
  additionalRequirementsList: [],
  actionTakenList: [],
  engineerObservationList: [],
  customerNotesList: [],
  actionTakenDetails: {},
  engineerObservationDetails: {},
  edititemReceivedDetails: {},
  defaultIssuesOptionDetails: {},
  additionalRequirementsOptionDetails: {},
  getJobCardOptionsBasedOnProductIdResp: {},
  guestJobCardDetails:{},
};

export const serviceManagementSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (
      state: serviceManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    // updateUserDetails: (
    //   state: serviceManagementState,
    //   action: PayloadAction<boolean>
    // ) => {
    //   state.user = action.payload;
    // },
    updateErrorMessage: (
      state: serviceManagementState,
      action: PayloadAction<boolean>
    ) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getAllBrands.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getAllBrands.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.brandsList = action.payload;
      }
    );
    builder.addCase(
      getAllBrands.rejected,
      (state: serviceManagementState, action: any) => {
        state.loading = false;
        state.brandsList = [];
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getDashboards
    builder.addCase(getDashboards.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getDashboards.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.dashboardData = action.payload;
      }
    );
    builder.addCase(
      getDashboards.rejected,
      (state: serviceManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    //getDisplayJobCardFormApi
    builder.addCase(getDisplayJobCardFormApi.pending, (state: any) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getDisplayJobCardFormApi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.displayJobCardDetails = action.payload;
      }
    );
    builder.addCase(
      getDisplayJobCardFormApi.rejected,
      (state: serviceManagementState, action: any) => {
        state.loading = false;
        state.error = action.error?.message ?? "Something went wrong";
      }
    );
    builder.addCase(
      getBrandsByProductIdApi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        console.log("action.payload", action.payload);
        state.brandsByProductIdResp = action.payload;
      }
    );
    builder.addCase(
      getItemsRecievedByProductIdAPi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.itemsRecievedByProductIdResp = action.payload?.itemRecivedOptions;
      }
    );
    builder.addCase(
      getDefaultIssuesByProductIdAPi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.defaultIssuesByProductIdResp =
          action.payload?.defaultIssuesOptions;
      }
    );
    builder.addCase(
      getCustomerAdditionalReqAPi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.customerAdditionalReqResp =
          action.payload?.additionalRequirementsOption;
      }
    );
    builder.addCase(
      getJobcardSummaryApi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.jobCardSummaryDetails = action.payload;
      }
    );
    //getFilteredjobcardsummaryApi
    builder.addCase(
      getFilteredjobcardsummaryApi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.jobCardSummaryDetails = action.payload;
        state.approvalJobCards = action.payload;
      }
    );
    //getJobCardDetailsApi
    builder.addCase(
      getJobCardDetailsApi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.jobCardDetails = action.payload;
        state.itemsRecievedByProductIdResp = action.payload?.itemRecivedOptions;
        state.defaultIssuesByProductIdResp =
          action.payload?.defaultIssuesOptions;
        state.customerAdditionalReqResp =
          action.payload?.additionalRequirementsOptions;
      }
    );
    //getProductsListApi
    builder.addCase(
      getProductsListApi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.productsList = action.payload;
      }
    );
    //getProductDetails
    builder.addCase(
      getProductDetails.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.productDetails = action.payload?.productInfo;
      }
    );
    //getBrandDetails
    builder.addCase(
      getBrandDetails.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.brandDetails = action.payload;
      }
    );
    //itemReceivedList
    builder.addCase(
      getItemReceivedList.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.itemsReceivedList = action.payload;
      }
    );
    //getDefaultIssuesList
    builder.addCase(
      getDefaultIssuesList.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.defaultIssuesList = action.payload;
      }
    );
    // getAdditionalRequirementsList
    builder.addCase(
      getAdditionalRequirementsList.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.additionalRequirementsList = action.payload;
      }
    );
    //getActionTakenList
    builder.addCase(
      getActionTakenList.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.actionTakenList = action.payload;
      }
    );
    //getEngineerObservationList
    builder.addCase(
      getEngineerObservationList.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.engineerObservationList = action.payload;
      }
    );
    //getCustomerNotesList
    builder.addCase(
      getCustomerNotesList.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.customerNotesList = action.payload;
      }
    );
    //getActionDetailsDetails actionTakenDetails
    builder.addCase(
      getActionDetailsDetails.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.actionTakenDetails = action.payload;
      }
    );
    //getEngineerobservationDetails
    builder.addCase(
      getEngineerobservationDetails.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.engineerObservationDetails = action.payload;
      }
    );
    //getEdititemReceivedDetails edititemReceivedDetails
    builder.addCase(
      getEdititemReceivedDetails.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.edititemReceivedDetails = action.payload;
      }
    );
    //getDefaultIssuesOptionDetails
    builder.addCase(
      getDefaultIssuesOptionDetails.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.defaultIssuesOptionDetails = action.payload;
      }
    );
    //getAdditionalRequirementsOptionDetails
    builder.addCase(
      getAdditionalRequirementsOptionDetails.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.additionalRequirementsOptionDetails = action.payload;
      }
    );
    //
    builder.addCase(
      getJobCardOptionsBasedOnProductId.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.getJobCardOptionsBasedOnProductIdResp = action.payload;
      }
    );
    //
    builder.addCase(
      getJobCardDetailsWithMobileNoApi.fulfilled,
      (state: serviceManagementState, action: PayloadAction<any>) => {
        state.error = null;
        state.loading = false;
        state.guestJobCardDetails = action.payload?.jobcardInfo;
      }
    );
  },
});

export const {
  setLoading,
  updateErrorMessage,
} = serviceManagementSlice.actions;

export default serviceManagementSlice.reducer;
