import React, { useState, useEffect, useRef } from "react";
import CommonReportData from "./CommonReportData";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Table } from "antd";
import { Chart } from "react-google-charts";
import { CommonReportStyled } from "./CommonReport.styled";
import DateRangeSelect from "./../../components/DateRangeSelect/DateRangeSelect";
import LineReportsDashboard from "./../../pages/ProductsDashboard/LineReportsDashboard";
import { getLineReportsByDate } from "../../redux/slices/Reports/ReportsService";

const CommonReports = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { lineJobCardReports } = useSelector((state: any) => state.reports);
  const buttonRef = useRef(null as any);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [allJobReports, setAllJobReports] = useState([]);
  const [allJobReportsChat, setAllJobReportsChat] = useState([]);
  useEffect(() => {
    console.log("useEffect");
    // getAllData();
    setStartDate(moment().subtract(30, "days").format("DD/MM/YYYY"));
    setEndDate(moment().format("DD/MM/YYYY"));
  }, []);
  useEffect(() => {
    console.log("useEffect");
    getAllData();
  }, [startDate, endDate]);
  useEffect(() => {
    var telplIst = [] as any;
    var telplIstChat = [] as any;
    console.log("lineJobCardReports", lineJobCardReports);
    // if (lineJobCardReports?.length > 0) {
    // const lineJobCardReports1 = lineJobCardReports?.reverse();
    lineJobCardReports?.map((item: any) => {
      telplIst.push({ ...item });
      telplIstChat.push([
        item?.reportDate,
        item?.openStatusCount,
        item?.closeStstusCount,
      ]);
    });
    telplIstChat.push(["Date", "Created Job cards", "Closed Job cards"]);

    // }
    setAllJobReportsChat(telplIstChat.reverse());
    setAllJobReports(telplIst.reverse());
  }, [lineJobCardReports]);

  const getAllData = () => {
    const body = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(getLineReportsByDate(body));
  };
  const handleFilterClick = (datesV?: any) => {
    console.log("datesV", datesV);
    setStartDate(moment(datesV[0]?.startDate).format("DD/MM/YYYY"));
    setEndDate(moment(datesV[0]?.endDate).format("DD/MM/YYYY"));
    buttonRef.current.click();
  };
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      key: 1,
      render: (item: any, record: any, index: any) => {
        const indexP = index + 1;
        return <>{indexP}</>;
      },
    },
    {
      title: "Date",
      width: "100px",
      key: 1,
      dataIndex: "reportDate",
      render: (name: any) => {
        return (
          <>
            <span className="table-option-text">{name} </span>
          </>
        );
      },
    },
    {
      title: "Open Job Cards",
      width: "30%",
      dataIndex: "openStatusCount",
      key: 1,
      render: (name: any) => {
        return (
          <>
            <span className="table-option-text">{name} </span>
          </>
        );
      },
    },
    {
      title: "Close Job Cards",
      width: "30%",
      dataIndex: "closeStstusCount",
      key: 1,
      render: (name: any) => {
        return (
          <>
            <span className="table-option-text">{name} </span>
          </>
        );
      },
    },
  ];

  const options = {
    title: "All Job Cards",
    chartArea: { width: "50%" },
    hAxis: {
      title: "Job Cards Count",
      minValue: 0,
    },
    vAxis: {
      title: "Date",
    },
  };

  console.log("lineJobCardReports", lineJobCardReports);
  console.log("allJobReports", allJobReports);
  const rowsCount = allJobReportsChat?.length;
  const countMulti = rowsCount > 20 ? 50 : 30;
  const minHeight = rowsCount * countMulti;
  console.log("rowsCount", rowsCount);
  return (
    <>
      <CommonReportStyled>
        <h4> Job cards Reports</h4>
        <div className="quick-search-section-list">
          <div className="accordion" id="accordionExample1">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne1">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne1"
                  aria-expanded="true"
                  aria-controls="collapseOne1"
                  ref={buttonRef}
                >
                  Select Custom Date wise
                </button>
              </h2>
              <div
                id="collapseOne1"
                className="accordion-collapse  collapse" //open //collapse
                aria-labelledby="headingOne1"
                data-bs-parent="#accordionExample1"
              >
                <div className="accordion-body">
                  <div className="line-reports-status-view-sec">
                    <DateRangeSelect handleFilterClick={handleFilterClick} />
                  </div>
                </div>
              </div>
            </div>
            <br />
            <h3>
              Showing Reports from {startDate} to {endDate}
            </h3>
          </div>
          <div className="line-dash-board-secion">
            <LineReportsDashboard fromReports={true} />
          </div>
          <div className="char-box-full-section-con">
            <Chart
              chartType="BarChart"
              width="100%"
              height={`1300px`}
              data={allJobReportsChat}
              options={options}
            />
          </div>
          <div className="line-dash-board-secion line-dash-board-secion-table">
            <Table
              columns={columns}
              dataSource={allJobReports}
              scroll={{ y: 540 }}
              pagination={false}
              className="all-reports-data-table"
            />
          </div>
        </div>
      </CommonReportStyled>
    </>
  );
};
export default CommonReports;
