import { createAsyncThunk } from "@reduxjs/toolkit";
import { SERVER_IP } from "../../../config";
import { post, put, get, del } from "../../../library/Requests/helpers";

export const getAllBrands = createAsyncThunk(
  "serviceManagement/getAllBrands",
  // async (body:any) => {
  //   return await post(`${SERVER_IP}/auth/login`, body);
  // }
  async () => await get(`${SERVER_IP}/brandlist`)
);
export const getDashboards = createAsyncThunk(
  "serviceManagement/getDashboards",
  async (flagV?: any) =>
    await get(`${SERVER_IP}/dashboard${flagV ? "?getMydata=true" : ""}`)
);
export const createJobCardApi = createAsyncThunk(
  "serviceManagement/createJobCardApi",
  async (body: any) => await post(`${SERVER_IP}/createJobCard`, body)
);

// export const signUpUser = createAsyncThunk(
//   "serviceManagement/signUpUser",
//   async (body: any) => await post(`${SERVER_IP}/auth/register`, body)
// );
export const updateJobcardItemApi = createAsyncThunk(
  "serviceManagement/updateJobcardItem",
  async (body: any) => await put(`${SERVER_IP}/updateJobcardItem`, body)
);
export const getProductsListApi = createAsyncThunk(
  "serviceManagement/getProductsListApi",
  async () => await get(`${SERVER_IP}/productlist`)
);
export const getJobCardDetailsApi = createAsyncThunk(
  "serviceManagement/getJobCardDetailsApi",
  async (id: any) => await get(`${SERVER_IP}/displayEditJobCardForm/${id}`)
);
export const getDisplayJobCardFormApi = createAsyncThunk(
  "serviceManagement/getDisplayJobCardFormApi",
  async (id: any) => await get(`${SERVER_IP}/displayJobCardForm`)
);
export const getBrandsByProductIdApi = createAsyncThunk(
  "serviceManagement/getBrandsByProductIdApi",
  async (body: any) =>
    await get(
      `${SERVER_IP}/getbrandsbyproductidtobookservice/${body?.PId}/${body?.jobId}`
    )
);
export const getItemsRecievedByProductIdAPi = createAsyncThunk(
  "serviceManagement/getItemsRecievedByProductIdAPi",
  async (body: any) =>
    await get(
      `${SERVER_IP}/getitemsrecievedbyproductidtobookservice/${body?.PId}/${body?.jobId}`
    )
);
export const getDefaultIssuesByProductIdAPi = createAsyncThunk(
  "serviceManagement/getDefaultIssuesByProductIdAPi",
  async (body: any) =>
    await get(
      `${SERVER_IP}/getdefaultissuesbyproductidtobookservice/${body?.PId}/${body?.jobId}`
    )
);
export const getCustomerAdditionalReqAPi = createAsyncThunk(
  "serviceManagement/getCustomerAdditionalReqAPi",
  async (body: any) =>
    await get(
      `${SERVER_IP}/getcustomeradditionalreqtobookservice/${body?.PId}/${body?.jobId}`
    )
);
export const getJobCardOptionsBasedOnProductId = createAsyncThunk(
  "serviceManagement/getJobCardOptionsBasedOnProductId",
  async (body: any) =>
    await get(
      `${SERVER_IP}/getJobCardOptionsBasedOnProduct/${body?.PId}/${body?.jobId}`
    )
);

export const getJobcardSummaryApi = createAsyncThunk(
  "serviceManagement/getJobcardSummaryApi",
  async (flagV?: any) =>
    await get(`${SERVER_IP}/jobcardsummary${flagV ? "?getMydata=true" : ""}`)
);
export const getFilteredjobcardsummaryApi = createAsyncThunk(
  "serviceManagement/getFilteredjobcardsummaryApi",
  async (param: any) =>
    await get(`${SERVER_IP}/filteredjobcardsummary?${param}`)
);
export const getFilteredjobcardsummaryApiPending = createAsyncThunk(
  "serviceManagement/getFilteredjobcardsummaryApiPending",
  async (param: any) =>
    await get(`${SERVER_IP}/filteredjobcardsummary?${param}`)
);

export const getProductDetails = createAsyncThunk(
  "serviceManagement/getProductDetails",
  async (param: any) => await get(`${SERVER_IP}/editproduct/${param}`)
);
export const getEdititemReceivedDetails = createAsyncThunk(
  "serviceManagement/getEdititemReceivedDetails",
  async (param: any) => await get(`${SERVER_IP}/edititemreceived/${param}`)
);
export const getDefaultIssuesOptionDetails = createAsyncThunk(
  "serviceManagement/getDefaultIssuesOptionDetails",
  async (param: any) =>
    await get(`${SERVER_IP}/editDefaultIssuesOption/${param}`)
);
export const getAdditionalRequirementsOptionDetails = createAsyncThunk(
  "serviceManagement/getAdditionalRequirementsOptionDetails",
  async (param: any) =>
    await get(`${SERVER_IP}/editAdditionalRequirementsOption/${param}`)
);

export const getActionDetailsDetails = createAsyncThunk(
  "serviceManagement/getActionDetailsDetails",
  async (param: any) => await get(`${SERVER_IP}/editactiontakenoption/${param}`)
);
export const getEngineerobservationDetails = createAsyncThunk(
  "serviceManagement/getEngineerobservationDetails",
  async (param: any) =>
    await get(`${SERVER_IP}/editengineerobservationoption/${param}`)
);

export const createProduct = createAsyncThunk(
  "serviceManagement/createProduct",
  async (param: any) => await post(`${SERVER_IP}/addOrUpdateProduct`, param)
);

export const updateProduct = createAsyncThunk(
  "serviceManagement/updateProduct",
  async (param: any) => await put(`${SERVER_IP}/updateProduct`, param)
);
export const getBrandDetails = createAsyncThunk(
  "serviceManagement/getBrandDetails",
  async (param: any) => await get(`${SERVER_IP}/editbrand/${param}`)
);
export const createBrand = createAsyncThunk(
  "serviceManagement/createBrand",
  async (param: any) => await post(`${SERVER_IP}/addOrUpdateBrand`, param)
);
export const updateBrand = createAsyncThunk(
  "serviceManagement/updateBrand",
  async (param: any) => await put(`${SERVER_IP}/updateBrand`, param)
);
export const getItemReceivedList = createAsyncThunk(
  "serviceManagement/getItemReceivedList",
  async (param: any) => await get(`${SERVER_IP}/displayitemsreceived`)
);
export const createItemReceivedOption = createAsyncThunk(
  "serviceManagement/createItemReceivedOption",
  async (param: any) =>
    await post(`${SERVER_IP}/addOrUpdateItemRecivedOption`, param)
);

export const getDefaultIssuesList = createAsyncThunk(
  "serviceManagement/getDefaultIssuesList",
  async (param: any) => await get(`${SERVER_IP}/displayDefaultIssuesOptions`)
);
export const createDefaultIssuesOption = createAsyncThunk(
  "serviceManagement/createDefaultIssuesOption",
  async (param: any) =>
    await post(`${SERVER_IP}/addOrUpdateDefaultIssuesOption`, param)
);

export const getAdditionalRequirementsList = createAsyncThunk(
  "serviceManagement/getAdditionalRequirementsList",
  async (param: any) =>
    await get(`${SERVER_IP}/displayCustomerAdditionalRequirements`)
);
export const createAdditionalRequirementsOption = createAsyncThunk(
  "serviceManagement/createAdditionalRequirementsOption",
  async (param: any) =>
    await post(`${SERVER_IP}/addOrUpdateAdditionalRequirementsOption`, param)
);

export const getActionTakenList = createAsyncThunk(
  "serviceManagement/getActionTakenList",
  async (param: any) => await get(`${SERVER_IP}/actiontakenlist`)
);
export const createActionTakenOption = createAsyncThunk(
  "serviceManagement/createActionTakenOption",
  async (param: any) =>
    await post(`${SERVER_IP}/addOrUpdateActionTakenOption`, param)
);

export const getEngineerObservationList = createAsyncThunk(
  "serviceManagement/getEngineerObservationList",
  async (param: any) => await get(`${SERVER_IP}/engineerobservationlist`)
);
export const createEngineerObservationOption = createAsyncThunk(
  "serviceManagement/createEngineerObservationOption",
  async (param: any) =>
    await post(`${SERVER_IP}/addOrUpdateEngineerObservationOption`, param)
);

export const getCustomerNotesList = createAsyncThunk(
  "serviceManagement/getCustomerNotesList",
  async (param: any) => await get(`${SERVER_IP}/customernotelist`)
);
export const createCustomerNotesOption = createAsyncThunk(
  "serviceManagement/createCustomerNotesOption",
  async (param: any) =>
    await post(`${SERVER_IP}/addOrUpdateCustomerNoteOption`, param)
);

export const getApprovedUsersCall = createAsyncThunk(
  "serviceManagement/getApprovedUsersCall",
  async (param: any) => {
    console.log("getApprovedUsersCallgetApprovedUsersCall");
    return await get(`${SERVER_IP}/getapprovedpendingstoreslist`);
  }
);

export const jobCardRejectCall = createAsyncThunk(
  "serviceManagement/jobCardRejectCall",
  async (id: any) => await get(`${SERVER_IP}/jobCardReject/${id}`)
);
export const jobCardClsoeCall = createAsyncThunk(
  "serviceManagement/jobCardClsoeCall",
  async (id: any) => await get(`${SERVER_IP}/jobCardClsoe/${id}`)
);
export const deleteproductCall = createAsyncThunk(
  "serviceManagement/deleteproductCall",
  async (id: any) => await del(`${SERVER_IP}/deleteproduct/${id}`)
);
export const deleteengineerobservationCall = createAsyncThunk(
  "serviceManagement/deleteengineerobservationCall",
  async (id: any) => await del(`${SERVER_IP}/deleteengineerobservation/${id}`)
);
export const deletebrandCall = createAsyncThunk(
  "serviceManagement/deletebrandCall",
  async (id: any) => await del(`${SERVER_IP}/deletebrand/${id}`)
);
export const deletedefaultissuesoptionCall = createAsyncThunk(
  "serviceManagement/deletedefaultissuesoptionCall",
  async (id: any) => await del(`${SERVER_IP}/deletedefaultissuesoption/${id}`)
);
export const deletecustomernoteCall = createAsyncThunk(
  "serviceManagement/deletecustomernoteCall",
  async (id: any) => await del(`${SERVER_IP}/deletecustomernote/${id}`)
);
export const deletecustomerCall = createAsyncThunk(
  "serviceManagement/deletecustomerCall",
  async (id: any) => await del(`${SERVER_IP}/deletecustomer/${id}`)
);
export const deleteadditionalrequirementsoptionCall = createAsyncThunk(
  "serviceManagement/deleteadditionalrequirementsoptionCall",
  async (id: any) =>
    await del(`${SERVER_IP}/deleteadditionalrequirementsoption/${id}`)
);
export const deleteitemrecivedoptionCall = createAsyncThunk(
  "serviceManagement/deleteitemrecivedoptionCall",
  async (id: any) => await del(`${SERVER_IP}/deleteitemrecivedoption/${id}`)
);
export const getJobCardDetailsWithMobileNoApi = createAsyncThunk(
  "serviceManagement/getJobCardDetailsWithMobileNoApi",
  async (body: any ) => await get(`${SERVER_IP}/getJobCardByIdAndMobileNo/${body?.jcardno}/${body?.mno}`)
);
