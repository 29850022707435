import { ProductsDashboardStyled } from "./ProductsDashboard.styled";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import Select from "react-select";
import CommonReportData from "./../Reports/CommonReportData";
import moment from "moment";

import Marquee from "react-fast-marquee";
import {
  statusList,
  severityOptions,
  statusListUpdate,
} from "./../../Scenes/constants";
import "./styles.css";
import { JobCardDashboardStyled } from "./../JobcardSummary/JobCardDashboard.styled";
import {
  getAllBrands,
  getItemReceivedList,
  getDefaultIssuesList,
  getAdditionalRequirementsList,
  getActionTakenList,
  getEngineerObservationList,
  getCustomerNotesList,
  deleteproductCall,
  deletebrandCall,
  deletedefaultissuesoptionCall,
  deleteengineerobservationCall,
  deletecustomernoteCall,
  deleteitemrecivedoptionCall,
  deleteadditionalrequirementsoptionCall,
} from "./../../redux/slices/serviceManagement/serviceManagementService";

import { getLineReportsByDate } from "../../redux/slices/Reports/ReportsService";

import {
  getAllUsersList,
  deleteUserApi,
} from "../../redux/slices/auth/authService";
import { checkIsMobile } from "../../Scenes/common";
import { Chart } from "react-google-charts";

// import { getDashboards } from "./../../../redux/slices/serviceManagement/serviceManagementService";

const LineReportsDashboard = (props: any) => {
  const { statusUpdate, fromReports } = props;
  const { dashboardData } = useSelector(
    (state: any) => state.serviceManagement
  );
  const { lineJobCardReports } = useSelector((state: any) => state.reports);

  const { error, loading, user, searchUsersList, allUsersList } = useSelector(
    (state: any) => state.auth
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);
  const [lineReportData, setLineReportData] = useState([] as any);
  console.log(
    "moment().subtract(15,",
    moment().subtract(15, "days").format("DD/MM/YYYY")
  );
  useEffect(() => {
    console.log("user", user);
    if (user?.id) {
      if (!fromReports) {
        const body = {
          startDate: moment().subtract(15, "days").format("DD/MM/YYYY"),
          endDate: moment().format("DD/MM/YYYY"),
        };
        // "01/01/2024"        : "30/01/2024",

        dispatch(getLineReportsByDate(body));
      }
    } else {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    if (lineJobCardReports?.length > 0) {
      let tempData = [] as any;
      tempData.push(["Date", "Created Job cards", "Closed Job cards"]);
      lineJobCardReports.map((item: any) => {
        const newDate1 =
          item?.reportDate?.split("/")[0] +
          "/" +
          item?.reportDate?.split("/")[1];
        tempData.push([
          newDate1,
          item?.openStatusCount,
          item?.closeStstusCount,
        ]);
      });
      setLineReportData(tempData);
    }
  }, [lineJobCardReports]);
  const handleViewAll = () => {
    history.push("/Reports");
  };
  const options22 = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };
  // console.log("lineJobCardReports", lineJobCardReports);
  // const data22 = [
  //   ["Date", "Created Job cards", "Closed Job cards"],
  //   ["01/12/2023", 8, 2],
  //   ["02/12/2023", 15, 6],
  //   ["03/12/2023", 10, 3],
  //   ["04/12/2023", 0, 3],
  //   ["05/12/2023", 3, 3],
  //   ["06/12/2023", 7, 2],
  //   ["07/12/2023", 1, 13],
  //   ["08/12/2023", 5, 2],
  //   ["09/12/2023", 12, 4],
  //   ["10/12/2023", 0, 5],
  //   ["11/12/2023", 10, 4],
  //   ["12/12/2023", 13, 3],
  //   ["13/12/2023", 7, 12],
  //   ["14/12/2023", 3, 9],
  //   ["15/12/2023", 2, 10],
  //   ["16/12/2023", 4, 6],
  // ];
  const isMo = checkIsMobile();
  return (
    <>
      <ProductsDashboardStyled>
        {/* <JobCardDashboardStyled> */}
        <div className="job-card-dashboard">
          <Chart
            chartType="Line" //
            width={isMo ? "100%" : "1050px"}
            height="100px"
            data={lineReportData}
            options={options22}
          />
          {!fromReports && (
            <div className="view-full-details-con">
              <span onClick={handleViewAll} className="view-full-details-sec">
                View Full Details
              </span>
            </div>
          )}
        </div>
        {/* </JobCardDashboardStyled> */}
      </ProductsDashboardStyled>
    </>
  );
};
export default LineReportsDashboard;
