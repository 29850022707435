import React, { useState, useEffect } from "react";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";
import type { ColumnsType } from "antd/es/table";
import { JobcardSummaryStyled } from "./JobcardSummary.styled";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import JobCardDashboard from "./JobCardDashboard";
import Button from "./../../components/Button/Button";
import moment from "moment";
import DatePicker1 from "./DatePicker1";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCustomerSearch } from "./../../redux/slices/auth/authService";
import ProductsDashboard from "./../../pages/ProductsDashboard/ProductsDashboard";
import LeadSummaryDashboard from "./../LeadManagement/LeadSummary/LeadSummaryDashboard";
import TodayStatusReport from "./../Reports/TodayStatusReport";
import {
  getJobcardSummaryApi,
  getFilteredjobcardsummaryApi,
  getJobCardDetailsApi,
  updateJobcardItemApi,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import JobCardDetails from "./../../pages/JobCardDetails/JobCardDetails";
import ConfirmationModal from "./../../components/Common/ConfirmationModal";
import LineReportsDashboard from "./../ProductsDashboard/LineReportsDashboard";
import { isRaskoAccount } from "./../../Scenes/common";
// import { statusList, selctfieldsList } from "./../../Scenes/constants";
import print_img from "./../../assets/icons8-print-35.png";
import arrow_icon from "./../../img/arrow-205.png";
import arrow_icon_down from "./../../img/arrow-206.png";
import arrow_icon1 from "./../../img/down-arrow-6402.png";
import Loader from "./../../components/Common/Loader";
import { checkIsMobile } from "../../Scenes/common";

import {
  statusList,
  severityOptions,
  statusListUpdate,
} from "./../../Scenes/constants";
import DateRangeSelect from "./../../components/DateRangeSelect/DateRangeSelect";
import { triggerMixpanelEvent, checkRolePermission } from "../../Scenes/common";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const fieldsData1 = {};

const JobcardSummary: React.FC = (props: any) => {
  const query = useQuery();

  const statusVal = query.get("status");

  const [fieldsData, setFieldsData] = useState(fieldsData1 as any);
  const [age, setAge] = useState("");
  const [status, setStatus] = useState(statusVal ?? "");
  const [assigne, setAssigne] = useState("");
  const [selectedField, setSeselectedField] = useState("");
  const [selectedValue, setSeselectedValue] = useState("");
  const [priorityValue, setPriorityValue] = useState("");
  const [currectActiveTab, setCurrectActiveTab] = useState("Service Dashboard");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fromDate1, setFromDate1] = useState("");
  const [fromDate2, setFromDate2] = useState("");
  const [toDate1, setToDate1] = useState("");
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDashboard, setFromDashboard] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [jsonBody, setJsonBody] = useState({});
  const [showDropDown, setShowDropDown] = useState(false);
  const [showDropDown1, setShowDropDown1] = useState(false);
  const [userSelectedInfo, setUserSelectedInfo] = useState({});

  const [usersList, setUsersList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  const { jobCardSummaryDetails } = useSelector(
    (state: any) => state.serviceManagement
  );
  const { loginStoreDetails, searchUsersList } = useSelector(
    (state: any) => state.auth
  );

  useEffect(() => {
    triggerMixpanelEvent("job_summary_page");
    if (!statusVal) {
      dispatch(getJobcardSummaryApi(false));
    }
    if (!loginStoreDetails?.address && loginStoreDetails?.id) {
      setShowWarningMessage(true);
    }
  }, []);
  // useEffect(() => {
  //   if (statusVal) {
  //     setStatus(statusVal);
  //   }
  // }, [statusVal]);
  useEffect(() => {
    handleSubmit(currentPage);
  }, [status, assigne, currentPage, priorityValue, toDate]);
  useEffect(() => {
    if (currectActiveTab === "Your Dashboard") {
      dispatch(getJobcardSummaryApi(true));
    } else {
      dispatch(getJobcardSummaryApi(false));
    }
  }, [currectActiveTab]);

  useEffect(() => {
    if (jobCardSummaryDetails?.users) {
      const usersList1 = [] as any;
      usersList1.push({ id: 0, value: "", label: "All" });

      jobCardSummaryDetails?.users?.map((user: any) => {
        const name =
          user?.firstName.toLowerCase() + " " + user?.lastName.toLowerCase();
        usersList1.push({ id: user?.id, value: user?.id, label: name });
      });
      setUsersList(usersList1);
    }
  }, [jobCardSummaryDetails]);
  // console.log("selectedField", selectedField);
  // console.log("selectedValue", selectedValue);
  // console.log("status", status);
  // console.log("status", status);
  // console.log("assigne", assigne);
  const handleClearFilter = () => {
    setStatus("");
    setAssigne("");
    setSeselectedField("");
    setSeselectedValue("");
    setFromDate("");
    setToDate("");
    setFromDate1("");
    setToDate1("");
  };
  const handleSubmit = async (pageN: any) => {
    const paramsList = [] as any;
    const page222 = pageN || currentPage;
    paramsList.push(`pageNo=${page222}`);
    if (fromDashboard) {
      paramsList.push(`fromDashboard=${fromDashboard}`);
    }
    if (status) {
      paramsList.push(`status=${status}`);
    }
    if (assigne) {
      paramsList.push(`selectedUserId=${assigne}`);
    }
    if (fromDate) {
      paramsList.push(`fromDate=${fromDate}`);
    }
    if (toDate) {
      paramsList.push(`endDate=${toDate}`);
    }
    if (priorityValue) {
      paramsList.push(`priority=${priorityValue}`);
    }
    if (currectActiveTab === "Your Dashboard") {
      paramsList.push(`getMydata=true`);
    }
    for (var key in fieldsData) {
      const Pvalue = fieldsData[key];
      if (Pvalue && Pvalue !== "") {
        paramsList.push(`${key}=${Pvalue}`);
      }
    }
    // if (selectedValue) {
    //   paramsList.push(`${selectedField}=${selectedValue}`);
    // }
    const paramsV = paramsList.join("&");
    // http://173.249.11.237:8080/focusnextjobcard/filteredjobcardsummary?pageNo=1
    // &jobNo=vinod2&serialNo1=vinod4&fromDate=2023-06-08&endDate=2023-06-23
    // &customerName=vinod1&mobileNo=8686864923&status=Closed&selectedUserId=5&refNo=vinod3
    // y?pageNo=1&fromDate=2023-06-08&endDate=2023-06-23&mobileNo=8686864923&status=Closed&selectedUserId=5
    setIsLoading(true);
    await dispatch(getFilteredjobcardsummaryApi(paramsV));
    setIsLoading(false);
  };
  const handleChange = (event: any) => {
    setFromDashboard(false);
    setSeselectedValue(event.target.value as string);
  };
  const handleChangeStatus = (e: any) => {
    setCurrentPage(1);
    // setStatus(e.value);
    let newarr = [] as any;
    e?.map((iit: any) => {
      newarr.push(iit.value);
    });
    setStatus(newarr.join(","));
  };
  const statusUpdate = (sts: any, keName?: any) => {
    setCurrentPage(1);

    setAssigne("");
    setSeselectedValue("");
    setPriorityValue("");
    setStatus("");
    setFromDate("");
    setFromDate1("");
    setToDate("");
    setToDate1("");
    triggerMixpanelEvent("dashboard_item_click", { status: sts });
    if (keName === "Priority") {
      setPriorityValue(sts);
      setFromDashboard(true);
    } else {
      setFromDashboard(false);
      setStatus(sts);
    }
  };
  const handleChangeAssigne = (e: any) => {
    setCurrentPage(1);
    setAssigne(e.value);
  };
  const handleChangeField = (e: any) => {
    setSeselectedField(e.value);
  };
  const onChangeValuePriority = (name: any, value: any, jobId: any) => {
    // const { name, value, jobId } = e.target;
    console.log("jobIdjobId", jobId);
    let jsonvalBody = {
      id: jobId,
      status: value,
    } as any;
    let newText = `Are you sure, do you want to change status to ${value}`;
    if (name == "priority") {
      newText = `Are you sure, do you want to change Priority to ${value}`;
      jsonvalBody = {
        id: jobId,
        priority: value,
      };
    } else {
    }
    setJsonBody(jsonvalBody);
    setIsConfirmModalOpen(true);
    setMessageText(newText);
  };
  const handleUpdateValue = async () => {
    setIsConfirmModalOpen(false);
    console.log("JsonBody", jsonBody);
    await dispatch(updateJobcardItemApi(jsonBody));
    setIsSuccessModalOpen(true);
    // dispatch(getJobcardSummaryApi());
    handleSubmit(1);
  };
  const editJobCardItem = (jobcard: any) => {
    triggerMixpanelEvent("job_card_edit_click", { jobId: jobcard });
    // history.push(``);
    window.location.href = `/editjobcard/${jobcard}`;
  };
  const gotoProfilePage = () => {
    history.push(`/myProfile`);
  };
  // getMydata

  const viewJobCardItem = (jobcard: any) => {
    triggerMixpanelEvent("job_card_view_click", { jobId: jobcard });
    dispatch(getJobCardDetailsApi(jobcard));
    setShowJobDetails(true);
  };
  const handleDateChange = (fromD: any, toDate: any) => {
    setFromDashboard(false);
    setFromDate(fromD);
    setToDate(toDate);
  };
  const handleChangeDateFrom = (sDate: any) => {
    setFromDashboard(false);
    console.log("sDate", sDate);
    const stateDate = moment(sDate).format("YYYY-MM-DD");
    setFromDate(stateDate);
    setFromDate1(moment(sDate).format("DD/MM/YYYY"));
    setFromDate2(sDate);
    // const enddDate = moment(picker.endDate._d).format("YYYY-MM-DD");
  };
  const handleChangeDateTo = (sDate: any) => {
    setFromDashboard(false);
    console.log("sDate", sDate);
    const stateDate = moment(sDate).format("YYYY-MM-DD");
    setToDate(stateDate);
    setToDate1(moment(sDate).format("DD/MM/YYYY"));

    // const enddDate = moment(picker.endDate._d).format("YYYY-MM-DD");
  };

  const printJobCardItem = (jID: any) => {
    triggerMixpanelEvent("job_card_print_click", { jobId: jID });
    // window.location.href = ;
    window.open(`/printJobCard/${jID}`, "_blank");
  };

  const downloadJobCardItem = (jID: any) => {
    console.log("download", jID);
    triggerMixpanelEvent("job_card_download_click", { jobId: jID });
    // window.location.href = `/printJobCard/${jID}`;
  };
  const deleteJobCardItem = (jID: any) => {
    console.log("delete", jID);
    triggerMixpanelEvent("job_card_delete_click", { jobId: jID });
    // window.location.href = `/printJobCard/${jID}`;
  };
  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const onChangeValue = (e: any) => {
    const { name, value } = e.target;
    const newList = fieldsData;
    newList[name] = value;
    setFieldsData(newList);
    setUpdateData(!updateData);
    if (name === "customerName") {
      getDropDownValues(value);
      setShowDropDown(true);
    } else if (name === "mobileNo") {
      getDropDownValues(value);
      setShowDropDown1(true);
    }
  };
  const getDropDownValues = (val: any) => {
    if (val) {
      dispatch(getCustomerSearch(val));
    }
  };
  const handleItemClick = async (item: any) => {
    setShowDropDown(false);
    setShowDropDown1(false);
    setUserSelectedInfo(item);
    const prevValues = { ...fieldsData };
    prevValues["customerName"] = item?.customerName;
    prevValues["mobileNo"] = item?.mobileNo;
    setFieldsData(prevValues);
    // const newList = requiredFields;
    // newList["customerName"] = false;
    // newList["mobileNo"] = false;
    // setRequiredFields(newList);

    if (item?.value) {
      // await setBrandValue(item?.label);
      // const regJson = {
      //   brandCode: item?.value,
      // };
      // setFieldValue("brand", item?.label);
      // setFieldValue("brandCode", item?.value);
      // setFieldValue("manufacturer", item?.brandOwner);
      // setFieldValue("manufacturerCode", item?.brandOwnerCode);
      // setFieldValue("brandOemflag", item?.brandOemflag);
      // dispatch(getManfacturerApi(regJson));
    }
  };
  const cancelPopup = () => {
    // dispatch(getJobcardSummaryApi());
    setIsConfirmModalOpen(false);
    setIsSuccessModalOpen(false);
  };
  const items = [
    {
      key: "print",
      label: <span className="drop-down-single-item-sec">Print</span>,
    },
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
    {
      key: "view",
      label: <span className="drop-down-single-item-sec">View</span>,
    },

    {
      key: "download",
      label: <span className="drop-down-single-item-sec">Download</span>,
    },
    {
      key: "delete",
      label: <span className="drop-down-single-item-sec">Delete</span>,
    },
  ];

  const columns = [
    {
      title: "Service ID",
      width: 80,
      dataIndex: "id",
      key: "0",
      fixed: "left",
      render: (id: any) => {
        return (
          <>
            <a target="_blank" href={`/jobcarddetails/${id}`}>
              {id}
            </a>
          </>
        );
      },
    },
    // {
    //   title: "Ref No",
    //   width: 100,
    //   dataIndex: "RefNo",
    //   key: "1",
    // },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "Date",
      width: 100,
      fixed: checkIsMobile() ? "" : "left",
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "Name",
      width: 150,
      fixed: checkIsMobile() ? "" : "left",
      render: (item: any) => {
        // const name = item?.firstName + " " + item?.lastName;
        return <>{item}</>;
      },
    },
    // {
    //   title: "Date",
    //   dataIndex: "createdDate",
    //   key: "Date",
    //   width: 100,

    //   render: (item: any) => {
    //     return <>{moment(item).format("DD/MM/YYYY")}</>;
    //   },
    // },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "2",
      width: 120,
    },

    {
      title: "Priority",
      // dataIndex: "priority",
      key: "2",
      width: 100,
      className: `priority_Select_P`,
      render: (item1: any) => {
        const currentS = item1?.priority;
        const sid = item1?.id;
        return (
          <>
            <div className={"assigned-to-sec1"}>
              <select
                // label={sid}
                name="priority"
                onChange={(e) => {
                  onChangeValuePriority("priority", e.target.value, sid);
                }}
                required
                value={currentS}
              >
                {severityOptions.map((sName: any) => {
                  return (
                    <option
                      value={sName}
                      selected={sName === currentS ? true : false}
                    >
                      {sName}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <span className={`priority_Select_${item}`}>{item}</span> */}
          </>
        );
      },
    },
    {
      title: "Status",
      // dataIndex: "status",
      key: "2",
      width: 170,
      render: (item1: any) => {
        const currentS = item1?.status;
        const sid = item1?.id;
        return (
          <>
            <div className={"assigned-to-sec1"}>
              <select
                // label={sid}
                name="status"
                onChange={(e) => {
                  onChangeValuePriority("status", e.target.value, sid);
                }}
                required
                value={currentS}
              >
                <option selected={true}>{currentS}</option>
                {statusListUpdate.map((itemStatus: any) => {
                  return (
                    <option
                      selected={itemStatus?.value === currentS ? true : false}
                    >
                      {itemStatus?.value}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* <span className={`priority_Select_${item}`}>{item}</span> */}
          </>
        );
      },
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "3",
      width: 120,
      render: (item: any) => {
        return <>{item}</>;
      },
    },

    {
      title: "Brand Name",
      dataIndex: "brandName",
      key: "42",
      width: 120,
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "4",
      width: 120,
    },
    {
      title: "Serial No",
      dataIndex: "serialNo1",
      key: "2",
      width: 120,
    },

    {
      title: "Problem",
      dataIndex: "problem",
      key: "5",
      width: 150,
    },
    {
      title: "Payment Type",
      dataIndex: "disWarrantyService",
      key: "6",
      width: 110,
      render: (item: any) => {
        const name = item == "Yes" ? "Warranty" : "Chargeable";
        return <>{name}</>;
      },
    },
    {
      title: "Incomming Source",
      dataIndex: "incommingSource",
      key: "64",
      width: 110,
      render: (item: any) => {
        const name = item == "Yes" ? "Warranty" : "Chargeable";
        return <>{item}</>;
      },
    },

    {
      title: "Created",
      dataIndex: "createdBy",
      key: "7",
      width: 150,
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "8",
      render: (item2: any) => {
        const name = item2;
        return <>{name?.toLowerCase()}</>;
      },
      width: 150,
    },
    { title: "Age", dataIndex: "age", key: "8", width: 70 },
    {
      title: "Last Updated Date",
      dataIndex: "modifiedDate",
      key: "8",
      width: 100,
      render: (item2: any) => {
        const name = item2;
        return <>{item2 ? moment(item2).format("DD/MM/YYYY h:mm A") : null}</>;
      },
    },
    {
      title: "last Updated User",
      dataIndex: "modifiedBy",
      key: "8",
      width: 120,
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 200,
    //   dataIndex: "id",
    //   render: (item: any) => {
    //     return (
    //       <>
    //         {/* {item} */}
    //         <span className="table-actions-btn">
    //           <span
    //             className="view-job-card"
    //             onClick={() => {
    //               viewJobCardItem(item);
    //             }}
    //           >
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="currentColor"
    //               className="bi bi-eye"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
    //               <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
    //             </svg>
    //           </span>
    //           <span
    //             onClick={() => {
    //               editJobCardItem(item);
    //             }}
    //           >
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="currentColor"
    //               className="bi bi-pencil-fill"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
    //             </svg>
    //           </span>

    //           <a className="redText" href="#">
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="currentColor"
    //               className="bi bi-trash-fill"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
    //             </svg>
    //           </a>

    //           <a className="grayText" href="#">
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="16"
    //               height="16"
    //               fill="currentColor"
    //               className="bi bi-file-arrow-down-fill"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5z" />
    //             </svg>
    //           </a>
    //           <span className="print-icon-main">
    //             <a target="_blank" href={`/printJobCard/${item}`}>
    //               <img src={print_img} />
    //             </a>
    //           </span>
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 70,
      dataIndex: "id",
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <Space size="middle">
            <Dropdown
              placement="topLeft"
              className="menu-items-sec-all"
              menu={{
                items,
                onClick: ({ key }) => {
                  if (key === "view") {
                    viewJobCardItem(itemId);
                  } else if (key === "edit") {
                    editJobCardItem(itemId);
                  } else if (key === "download") {
                    downloadJobCardItem(itemId);
                  } else if (key === "delete") {
                    deleteJobCardItem(itemId);
                  } else if (key === "print") {
                    printJobCardItem(itemId);
                  }
                },
              }}
            >
              <span className="hover-menu-item-sec">...</span>
            </Dropdown>
          </Space>
        );
      },
    },
  ] as any;
  const statusList1 = [{ id: 0, value: "", label: "All" }];
  const statusList3 = [
    { id: 5, value: "Closed", label: "Closed" },
    { id: 5, value: "Closing Approval", label: "Closing Approval" },
    { id: 5, value: "Re Open", label: "Re Open" },
  ];

  const statusList2 = [...statusList1, ...statusList, ...statusList3];
  console.log("fieldsData", fieldsData);
  const tabsList = ["Service Dashboard", "Your Dashboard"];

  tabsList.push("Today Status Report");
  if (isRaskoAccount()) {
    // tabsList.push("Products Dashboard");
    tabsList.push("Lead Dashboard");
  }
  return (
    <JobcardSummaryStyled>
      <div className="job-card-summary">
        <ConfirmationModal
          className="club-confirmation"
          show={isConfirmModalOpen}
          onHide={cancelPopup}
          description={messageText}
          onConfirmClick={handleUpdateValue}
          confirmLabel="UPDATE"
          cancelLabel="CANCEL"
        ></ConfirmationModal>

        <ConfirmationModal
          className="club-confirmation"
          show={isSuccessModalOpen}
          onHide={cancelPopup}
          description="Successfully updated"
          onConfirmClick={cancelPopup}
          confirmLabel="OKAY"
          // cancelLabel="CANCEL"
        ></ConfirmationModal>

        <Modal
          size="xl"
          show={showJobDetails}
          className="job-card-details-popup"
          onHide={() => setShowJobDetails(false)}
          aria-labelledby="job-card-details-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="job-card-details-lg">
              Job Card Details{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JobCardDetails />
          </Modal.Body>
        </Modal>
        {isLoading && <Loader />}
        {showWarningMessage && (
          <div className="profile-warning-message-text">
            <p>
              Please complete the profile setup, profile details will display in
              print page.
            </p>
            <button onClick={gotoProfilePage}>Complete Profle</button>
          </div>
        )}
        {/* <div className="section-container-header-new">
          <div></div>
        </div> */}
        <h2 className="section-container-header-new section-container-header-new-new">
          <ul>
            {tabsList.map((tabV: any) => {
              return (
                <li
                  className={currectActiveTab === tabV ? "selected-tab" : ""}
                  onClick={() => {
                    setCurrectActiveTab(tabV);
                    // if (tabV === "Today Status Report") {
                    //   history.push("/todayStatusReport");
                    // }
                  }}
                >
                  {tabV}
                </li>
              );
            })}

            {/* <li> Your Dashboard</li>
            <li> Products Dashboard</li> */}
          </ul>
        </h2>
        {currectActiveTab === "Products Dashboard" ? (
          <>
            <ProductsDashboard />
          </>
        ) : currectActiveTab === "Lead Dashboard" ? (
          <>
            <LeadSummaryDashboard />
          </>
        ) : currectActiveTab === "Today Status Report" ? (
          <>
            <TodayStatusReport fromDashboard={true} />
          </>
        ) : (
          <>
            <JobCardDashboard
              statusUpdate={statusUpdate}
              currectActiveTab={currectActiveTab}
            />
            <LineReportsDashboard />
            <div className="quick-search-section-list">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Quick Search
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse  collapse" //open //collapse
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <div className=" row ">
                        {/* <div className="col-sm-6 mt-15 date-select-range-sec">
                      <DatePicker1 handleDateChange={handleDateChange} />
                    </div> */}
                        {/* <div className="col-sm-6 mt-15 row range-date-fields date-select-range-sec"> */}
                        <div className="col-sm-3 product-name-drop-down range-date-fields">
                          <label htmlFor="" className="col-form-label">
                            Start Date <span className="manditary">*</span>
                          </label>
                          <DatePicker
                            value={fromDate1}
                            name="dateOfBirth"
                            placeholderText={"Start Date"}
                            autoComplete="off"
                            onChange={handleChangeDateFrom}
                            // showMonthDropdown={true}
                            // showYearDropdown={true}
                            // maxDate={newminData._d}
                            // minDate={newmaxData._d}
                            // showMonthDropdown
                            // showYearDropdown
                            dropdownMode="select"
                            // scrollableYearDropdown={true}
                            dateFormat="dd/mm/yyyy"
                            onKeyDown={(e: any) => {
                              e.preventDefault();
                            }}
                          />
                          {/* <div>To</div> */}
                        </div>

                        <div className="col-sm-3 product-name-drop-down range-date-fields">
                          <label htmlFor="" className="col-form-label">
                            End Date <span className="manditary">*</span>
                          </label>
                          <DatePicker
                            value={toDate1}
                            name="dateOfBirth"
                            placeholderText={"End Date"}
                            autoComplete="off"
                            onChange={handleChangeDateTo}
                            // showMonthDropdown={true}
                            // showYearDropdown={true}
                            // maxDate={newminData._d}
                            // minDate={fromDate1}
                            // showMonthDropdown
                            // showYearDropdown
                            dropdownMode="select"
                            // scrollableYearDropdown={true}
                            dateFormat="dd/mm/yyyy"
                            onKeyDown={(e: any) => {
                              e.preventDefault();
                            }}
                          />
                        </div>
                        {/* </div> */}

                        {/* <div className="select-dropdown-filters row col-sm-6 mt-15"> */}
                        <div className="col-sm-3 product-name-drop-down">
                          <Form.Group>
                            <label htmlFor="" className="col-form-label">
                              Select Status <span className="manditary">*</span>
                            </label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue="Select"
                              isSearchable={true}
                              isMulti={true}
                              options={statusList2 as any}
                              onChange={(e) => {
                                setFromDashboard(false);
                                handleChangeStatus(e);
                              }}
                              required
                              placeholder={status ? status : "Select..."}
                              // value={jobData?.brandName}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-sm-3 product-name-drop-down">
                          <Form.Group>
                            <label htmlFor="" className="col-form-label">
                              Assigned To <span className="manditary">*</span>
                            </label>
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue="Select"
                              isSearchable={true}
                              options={usersList}
                              onChange={(e) => {
                                setFromDashboard(false);
                                handleChangeAssigne(e);
                              }}
                              required
                            />
                          </Form.Group>
                        </div>
                        {/* </div> */}
                      </div>
                      {/* <div> */}
                      <div className="row mt-15">
                        <div className="col-sm-3 ">
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Customer Name
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              value={fieldsData?.customerName}
                              type="text"
                              name="customerName"
                              id="customerName"
                              onChange={onChangeValue}
                            />
                          </Form.Group>
                          {showDropDown && fieldsData?.customerName && (
                            <div className="menu-items-list col-12">
                              <ul id="results" className="list-group">
                                {isLoading && (
                                  <li className="list-group-item list-group-item-action">
                                    Loading...
                                  </li>
                                )}
                                {searchUsersList?.length === 0 && (
                                  <li
                                    onClick={handleItemClick}
                                    className="list-group-item list-group-item-action"
                                  >
                                    No results found.
                                  </li>
                                )}
                                {searchUsersList?.map((item: any) => {
                                  return (
                                    <li
                                      onClick={() => {
                                        handleItemClick(item);
                                      }}
                                      className="list-group-item list-group-item-action"
                                    >
                                      {item?.customerName}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </div>

                        <div className="col-sm-3 ">
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Mobile No
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              value={fieldsData?.mobileNo}
                              type="text"
                              name="mobileNo"
                              id="mobileNo"
                              onChange={onChangeValue}
                            />
                          </Form.Group>
                          {showDropDown1 && fieldsData?.mobileNo && (
                            <div className="menu-items-list col-12">
                              <ul id="results" className="list-group">
                                {isLoading && (
                                  <li className="list-group-item list-group-item-action">
                                    Loading...
                                  </li>
                                )}
                                {searchUsersList?.length === 0 && (
                                  <li
                                    onClick={handleItemClick}
                                    className="list-group-item list-group-item-action"
                                  >
                                    No results found.
                                  </li>
                                )}
                                {searchUsersList?.map((item: any) => {
                                  return (
                                    <li
                                      onClick={() => {
                                        handleItemClick(item);
                                      }}
                                      className="list-group-item list-group-item-action"
                                    >
                                      {item?.customerName}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-3 ">
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Service ID
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              value={fieldsData?.jobNo}
                              type="text"
                              name="jobNo"
                              id="jobNo"
                              onChange={onChangeValue}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-sm-3 ">
                          <Form.Group>
                            <Form.Label className="requiredField">
                              Seril No 1
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              value={fieldsData?.serialNo1}
                              type="text"
                              name="serialNo1"
                              id="serialNo1"
                              onChange={onChangeValue}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      {/* </div> */}
                      <div className=" row filter-action-sec">
                        <div className="col-sm-3 "></div>
                        <div className="col-sm-3 "></div>
                        <div className="col-sm-3 "></div>
                        {/*  <div className="col-sm-9 mt-15 search-row-sec">
                   <div className="search-row-select">
                        <Form.Group>
                          <label htmlFor="" className="col-form-label">
                            Select Field <span className="manditary">*</span>
                          </label>
                          <Select
                            className="basic-single"
                            classNamePrefix="select"
                            defaultValue="Select"
                            isSearchable={true}
                            options={selctfieldsList as any}
                            onChange={(e) => {
                              setFromDashboard(false);
                              handleChangeField(e);
                            }}
                            required
                          />
                        </Form.Group>
                      </div>
                      <div className="search-row-search">
                        <input
                          value={selectedValue}
                          onChange={handleChange}
                          placeholder="Enter Value"
                        />
                      </div> 
                    </div>*/}
                        <div className="col-sm-3 5 filter-actions-btn">
                          <Button
                            onClick={() => {
                              setCurrentPage(1);
                              handleSubmit(1);
                            }}
                            text="Filter"
                          />
                          <div className="download-icon">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-file-arrow-down"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5z" />
                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="quick-search-section-list">
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne1">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne1"
                      aria-expanded="true"
                      aria-controls="collapseOne1"
                    >
                      Job Cards day wise
                    </button>
                  </h2>
                  <div
                    id="collapseOne1"
                    className="accordion-collapse  collapse" //open //collapse
                    aria-labelledby="headingOne1"
                    data-bs-parent="#accordionExample1"
                  >
                    <div className="accordion-body">
                      <div className="line-reports-status-view-sec">
                        <LineReportsDashboard />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <h2
          onClick={() => {
            setShowSearch(!showSearch);
          }}
          className="section-container-header-new section-container-header-new-search"
        >
          <span> Quick Search </span>{" "}
          <span>
            {" "}
            <img src={showSearch ? arrow_icon_down : arrow_icon} />{" "}
          </span>
          
        </h2> */}
            {/* <span onClick={handleClearFilter} className="clear-filters-sec">
              Clear Filters
            </span> */}
            {/* <div className="page-sec-header"></div> */}
            {showSearch && <></>}
            {/* <div className="jobcard-filters">
          <DateRangePicker
            ranges={range}
            alwaysShowCalendars={true}
            onEvent={handleEvent}
          >
            <button>
              {moment(fromDate).format("LL")} to {moment(toDate).format("LL")}
            </button>
          </DateRangePicker>
        </div> */}
            <h2 className="section-container-header-new d-flex-s-b ">
              <span>
                {" "}
                Job Summary (
                {jobCardSummaryDetails?.jobCards?.length < 10
                  ? jobCardSummaryDetails?.jobCards?.length
                  : 10 * jobCardSummaryDetails?.noOfPages}
                )
              </span>

              {checkRolePermission("createjobcard") && (
                <>
                  <span className="middle-line-space-sec">
                    {``}|{``}
                  </span>
                  <span>
                    <a href="/createjobcard">Create Job Card</a>
                  </span>
                </>
              )}
            </h2>

            <div className="jobcard-content">
              <Table
                columns={columns}
                pagination={false}
                dataSource={jobCardSummaryDetails?.jobCards}
                scroll={{ x: 1500, y: 500 }}
                rowClassName={(record, index) =>
                  `row_selected_priority_${record?.priority}`
                }

                // total={50}
              />
              {/* <Pagination defaultCurrent={1} total={50} /> */}
              {/* noOfPages */}
              <div className="job-summary-pagination">
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  total={10 * jobCardSummaryDetails?.noOfPages}
                  onChange={(val) => {
                    pageonChange(val);
                  }}
                  pageSize={10}
                  showSizeChanger={false}
                  // size="small"
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div></div>
    </JobcardSummaryStyled>
  );
};

export default JobcardSummary;
