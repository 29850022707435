import React, { useState, useEffect } from "react";
import { Table, Badge, Dropdown, Space, Pagination } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import Button from "./../../components/Button/Button";
import moment from "moment";
import {
  getJobcardSummaryApi,
  getFilteredjobcardsummaryApi,
  getJobCardDetailsApi,
  jobCardRejectCall,
  jobCardClsoeCall,
} from "./../../redux/slices/serviceManagement/serviceManagementService";
import JobCardDetails from "./../../pages/JobCardDetails/JobCardDetails";
import { statusList, selctfieldsList } from "./../../Scenes/constants";
import print_img from "./../../assets/icons8-print-35.png";
import arrow_icon from "./../../img/arrow-205.png";
import arrow_icon_down from "./../../img/arrow-206.png";
import arrow_icon1 from "./../../img/down-arrow-6402.png";
// import Loader from "./../../components/Common/Loader";
import { triggerMixpanelEvent } from "../../Scenes/common";
import CustomModal from "./../../components/CustomModel";
import { JobCardsApprovalStyled } from "./JobCardsApproval.styled";
import Loader from "./../../components/Common/Loader";

const JobCardsApproval = (props: any) => {
  const { selectedData, addUserSuccess, onHide, addPasswordSuccess } = props;
  const { error, loading, user } = useSelector((stateD: any) => stateD?.auth);
  const history = useHistory();
  const [passError, setPassError] = useState(false);
  const [passCError, setCPassError] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [showConformUserPopup, setShowConformUserPopup] = useState(false);
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, errorErrorMessage] = useState("");
  const [warningMessage, errorWarningMessage] = useState("");
  const [btnText, setBtnText] = useState("");
  const [showJobDetails, setShowJobDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedJobData, setSelectedJobData] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const { jobCardSummaryDetails, approvalJobCards } = useSelector(
    (state: any) => state.serviceManagement
  );

  const dispatch = useDispatch();
  console.log("user", user);
  useEffect(() => {
    triggerMixpanelEvent("job_cards_approval");
    // dispatch(getJobcardSummaryApi());
    allPendingApprovalJobs(1);
    // status:
  }, []);
  useEffect(() => {
    allPendingApprovalJobs(currentPage);
  }, [currentPage]);
  const allPendingApprovalJobs = async (pageN: any) => {
    const paramsList = [] as any;
    const page222 = pageN || currentPage;
    paramsList.push(`pageNo=${page222}`);
    paramsList.push(`status=Closing Approval`);
    const paramsV = paramsList.join("&");
    await dispatch(getFilteredjobcardsummaryApi(paramsV));
  };
  const pageonChange = (page: any) => {
    console.log("page", page);
    setCurrentPage(page);
  };
  const handleSubmit = async (pageN: any) => {
    const paramsList = [] as any;
    const page222 = pageN || currentPage;
    paramsList.push(`pageNo=${page222}`);

    const paramsV = paramsList.join("&");
    setIsLoading(true);
    await dispatch(getFilteredjobcardsummaryApi(paramsV));
    setIsLoading(false);
  };

  const handleChangeValue = (e: any) => {
    errorErrorMessage("");
    let { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    } else if (name === "currentPassword") {
      setCurrentPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };

  const hideModel = () => {
    // history.push("/login");
    setShowConformUserPopup(false);
    setShowSuccessPopup(false);
  };
  const hideModelOk = () => {
    history.push("/jobcardsummary");
    setShowConformUserPopup(false);
  };
  const viewJobCardItem = (jobcard: any) => {
    console.log("jobcardjobcard", jobcard);
    triggerMixpanelEvent("job_card_view_click", { jobId: jobcard });
    dispatch(getJobCardDetailsApi(jobcard));
    setShowJobDetails(true);
  };
  const closeJobCard = (jobcard: any) => {
    setShowConformUserPopup(true);
    setBtnText("close");
    setSelectedJobData(jobcard);
    errorWarningMessage("Are you sure, you want to Close job card?");
  };

  const rejectJobCard = (jobcard: any) => {
    setSelectedJobData(jobcard);
    setShowConformUserPopup(true);
    setBtnText("reject");
    errorWarningMessage("Are you sure, you want to Reject job card?");
  };
  const handleConformBtn = async () => {
    if (selectedJobData) {
      setShowConformUserPopup(false);
      setIsLoading(true);
      if (btnText === "reject") {
        await dispatch(jobCardRejectCall(selectedJobData));
      } else {
        await dispatch(jobCardClsoeCall(selectedJobData));
      }
      setShowSuccessPopup(true);
      setIsLoading(false);
      allPendingApprovalJobs(currentPage);
      if (btnText === "reject") {
        setSuccessMessage("Successfully Rejected the job Card.");
      } else {
        setSuccessMessage("Successfully Closed the job Card.");
      }
    }
    console.log("btn text", btnText);
  };
  const editJobCardItem = (jobcard: any) => {
    triggerMixpanelEvent("job_card_edit_click", { jobId: jobcard });
    history.push(`/editjobcard/${jobcard}`);
  };
  const items = [
    {
      key: "view",
      label: <span className="drop-down-single-item-sec">View</span>,
    },
    {
      key: "close",
      label: <span className="drop-down-single-item-sec">Close</span>,
    },
    {
      key: "reject",
      label: <span className="drop-down-single-item-sec">Reject</span>,
    },
    {
      key: "edit",
      label: <span className="drop-down-single-item-sec">Edit</span>,
    },
  ];

  const columns = [
    {
      title: "Service ID",
      width: 80,
      dataIndex: "id",
      key: "0",
      fixed: "left",
      render: (id: any) => {
        return (
          <>
            <a target="_blank" href={`/jobcarddetails/${id}`}>
              {id}
            </a>
          </>
        );
      },
    },

    {
      title: "Date",
      dataIndex: "createdDate",
      key: "Date",
      width: 100,
      fixed: "left",
      render: (item: any) => {
        return <>{moment(item).format("DD/MM/YYYY")}</>;
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "Name",
      width: 150,
      fixed: "left",
      render: (item: any) => {
        // const name = item?.firstName + " " + item?.lastName;
        return <>{item}</>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "2",
      width: 120,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "2",
      width: 80,
    },
    {
      title: "Product",
      dataIndex: "productName",
      key: "3",
      width: 120,
      render: (item: any) => {
        return <>{item}</>;
      },
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "4",
      width: 120,
    },

    {
      title: "Problem",
      dataIndex: "problem",
      key: "5",
      width: 150,
    },
    {
      title: "Payment Type",
      dataIndex: "disWarrantyService",
      key: "6",
      width: 110,
      render: (item: any) => {
        const name = item == "Yes" ? "Warranty" : "Chargeable";
        return <>{name}</>;
      },
    },
    {
      title: "Incomming Source",
      dataIndex: "incommingSource",
      key: "64",
      width: 110,
      render: (item: any) => {
        const name = item == "Yes" ? "Warranty" : "Chargeable";
        return <>{item}</>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "2",
      width: 220,
    },
    {
      title: "Created",
      dataIndex: "createdBy",
      key: "7",
      width: 150,
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "8",
      render: (item2: any) => {
        const name = item2;
        return <>{name?.toLowerCase()}</>;
      },
      width: 150,
    },
    { title: "Age", dataIndex: "age", key: "8", width: 70 },
    {
      title: "Last Updated Date",
      dataIndex: "modifiedDate",
      key: "8",
      width: 100,
      render: (item2: any) => {
        const name = item2;
        return <>{item2 ? moment(item2).format("DD/MM/YYYY h:mm A") : null}</>;
      },
    },
    {
      title: "last Updated User",
      dataIndex: "modifiedBy",
      key: "8",
      width: 120,
    },

    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 70,
      dataIndex: "id",
      render: (itemId: any) => {
        console.log("item", itemId);

        return (
          <Space size="middle">
            <Dropdown
              placement="topLeft"
              className="menu-items-sec-all"
              menu={{
                items,
                onClick: ({ key }) => {
                  if (key === "view") {
                    viewJobCardItem(itemId);
                  } else if (key === "edit") {
                    editJobCardItem(itemId);
                  } else if (key === "close") {
                    closeJobCard(itemId);
                  } else if (key === "reject") {
                    rejectJobCard(itemId);
                  }
                },
              }}
            >
              <span className="hover-menu-item-sec">...</span>
            </Dropdown>
          </Space>
        );
      },
    },
  ] as any;

  return (
    <JobCardsApprovalStyled>
      <div className="new-option-body">
        {isLoading && <Loader />}
        <Modal
          size="xl"
          show={showJobDetails}
          className="job-card-details-popup"
          onHide={() => setShowJobDetails(false)}
          aria-labelledby="job-card-details-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="job-card-details-lg">
              Job Card Details{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <JobCardDetails />
          </Modal.Body>
        </Modal>
        <CustomModal
          show={showConformUserPopup}
          titleText={warningMessage}
          cancelText="Cancel"
          deleteText={btnText}
          onCancel={hideModel}
          onDelete={() => {
            handleConformBtn();
          }}
        />
        <CustomModal
          show={showSuccessPopup}
          titleText={successMessage}
          // cancelText="Cancel"
          deleteText="OK"
          onCancel={hideModel}
          onDelete={() => {
            hideModel();
          }}
        />

        <h2 className="section-container-header-new d-flex-s-b ">
          <span> Job Cards Approval Pending List </span>
        </h2>

        <div className="jobcard-content">
          <Table
            columns={columns}
            pagination={false}
            dataSource={jobCardSummaryDetails?.jobCards}
            scroll={{ x: 1500, y: 500 }}
            // total={50}
          />
          <div className="job-summary-pagination">
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={10 * jobCardSummaryDetails?.noOfPages}
              onChange={(val) => {
                pageonChange(val);
              }}
              pageSize={10}
              showSizeChanger={false}
              // size="small"
            />
          </div>
        </div>
      </div>
    </JobCardsApprovalStyled>
  );
};

export default JobCardsApproval;
